<template>
  <div id="app">
    <div id="terminal">
      <div class="prompt">
        <h2>
          Wake up, Henric...
        </h2>
      </div>
      <div class="footer">
        <a href="https://www.github.com/kekke88" alt="GitHub Profile">
          <img src="@/assets/GitHub-Mark-Light-32px.png" height="18" /> GitHub
        </a>
         | 
        <a href="https://se.linkedin.com/in/henric-johansson-4156668b" alt="LinkedIn Profile">
          <img src="@/assets/LI-In-Bug.png" height="18" /> LinkedIn
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
body {
  background-color: #000;
  color: #03A062;
  font: 1.3rem Inconsolata, monospace;
  font-weight: bold;
}
body::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: repeating-linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15) 1px, transparent 1px, transparent 2px);
  pointer-events: none;
}
#app {
  display: flex;
}
#terminal {
  padding: 20px;
}
.prompt h2::before {
  content: ">";
}
.prompt h2 {
  padding-right: 5px;
  border-right: 2px solid #03A062;
  animation: write 2s steps(40, end), blink 0.5s step-end infinite;
  overflow: hidden;
  white-space: nowrap;
  letter-spacing: 0.15em; /* Adjust as needed */
}
.footer {
  position: absolute;
  bottom: 20px;
  line-height: 20px;
  font-size: 20px;
}
.footer a {
  text-decoration: none;
  color: #03A062;
}
.footer a:hover {
  color: #25C284;
}

@media only screen and (max-width: 720px) {
  .prompt h2 {
    font-size: 14px;
  }
  .footer {
    font-size: 12px;
  }
}

@keyframes write {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink {
  from {
    border-color: transparent;
  }
  50% {
    border-color: #03A062;
  }
}
</style>
